.bottom-nav {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 1;
  height: 64px;
  border-top: 1px solid #ebebeb;
}

.bottom-nav-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
}

.bottom-nav-item.active {
  background-color: #6593f5;
  color: white;
}

.bottom-nav-item--title {
  margin-top: 5px;
  font-size: 10.3px;
  margin-bottom: 0px;
}
